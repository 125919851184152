import React from 'react'
import { PulseLoader } from 'react-spinners'

export const Loading = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <PulseLoader color={'#d2232a'} loading={true} size={15} margin={2} />
    </div>
  )
}
