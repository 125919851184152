import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
//import 'firebase/analytics'
//import 'firebase/auth'

import { FirebaseProdConfig, FirebaseStagingConfig } from 'config'

let firebaseConfig = FirebaseStagingConfig

const env = process.env.REACT_APP_ENVIRONMENT
if (env == 'production') firebaseConfig = FirebaseProdConfig

console.log('Init firebase')
firebase.initializeApp(firebaseConfig)

const usingEmulator = process.env.REACT_APP_USE_EMULATOR
export const cf = firebase.app().functions('australia-southeast1')

if (usingEmulator == 'yes') {
  console.log('Starting using emulator:', usingEmulator)
  //firebase.auth().useEmulator(`http://localhost:${process.env.REACT_APP_EMULATOR_AUTH_PORT}`)
  firebase.firestore().useEmulator('localhost', process.env.REACT_APP_EMULATOR_FIRESTORE_PORT)
  cf.useEmulator('localhost', process.env.REACT_APP_EMULATOR_FUNCTIONS_PORT)
  firebase.storage().useEmulator('localhost', process.env.REACT_APP_EMULATOR_STORAGE_PORT)
}

export default firebase
