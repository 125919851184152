import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

export const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/quote" />
  },
  {
    path: '/quote',
    exact: true,
    component: lazy(() => import('pages/Quote'))
  },
  {
    path: '/quote/:id',
    exact: true,
    component: lazy(() => import('pages/Quote'))
  },
  {
    path: '/quote/:id',
    exact: true,
    component: lazy(() => import('pages/Quote'))
  },
  {
    path: '/quote/:id/:action',
    exact: true,
    component: lazy(() => import('pages/Quote/Action'))
  },
  {
    component: lazy(() => import('pages/Error/Error404'))
  }
]
