import './style/App.css'
import { renderRoutes } from 'react-router-config'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import { Router } from 'react-router-dom'
import { routes } from './routes'

export const history = createBrowserHistory()

const useSentry = process.env.REACT_APP_USE_SENTRY
const sentrySetting = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT
}

if (useSentry == 'true') Sentry.init(sentrySetting)

const App = () => {
  return (
    <div className="App App-header">
      <Sentry.ErrorBoundary fallback={'An error has occurred'}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default App
