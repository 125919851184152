export default {
  option: {
    '&:hover': {
      backgroundColor: '#f8dedf'
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#f8dedf'
    },
    '&[data-focus="true"]': {
      backgroundColor: '#f8dedf'
    }
  }
}
