import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './style/index.css'
import { Loading } from 'components/loading'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './theme'
import firebase from 'utils/firebase'

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </ThemeProvider>
  </RecoilRoot>,
  document.getElementById('root')
)
