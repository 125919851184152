export default {
  root: {
    borderRadius: 5,
    overflow: 'hidden',
    height: '10px'
  },
  colorPrimary: {
    backgroundColor: '#e99397'
  }
}
