export default {
  root: {
    fontFamily: "'DM Sans', sans-serif",
    fontWeight: 'bold',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '17px',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  textColorPrimary: {
    color: '#fdfdfd'
  }
}
