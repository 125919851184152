import { createTheme } from '@material-ui/core'
import palette from './palette'
import overrides from './overrides'

const baseTheme = {
  palette,
  overrides
}

export const theme = createTheme(baseTheme)
