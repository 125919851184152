const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY

const firebaseConfig = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: '682144624096',
  appId: '1:682144624096:web:5eb739127c74d4c37d8f85',
  measurementId: 'G-RDBFC6KCVX'
}

export default firebaseConfig
