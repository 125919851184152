export default {
  button: {
    '&:hover': {
      backgroundColor: '#f8dedf'
    }
  },
  root: {
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: '#f8dedf'
      },
      backgroundColor: '#f8dedf'
    }
  }
}
