import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'
const red = '#d2232a'

export default {
  primary: {
    // contrastText: white,
    dark: white,
    main: '#d2232a',
    light: white
  },
  secondary: {
    // contrastText: white,
    dark: black,
    main: white,
    light: white
  },
  default: { contrastText: white, dark: colors.brown[900], main: colors.brown[500], light: colors.brown[100] },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#fff',
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: colors.blue[800],
  icon: red,
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: colors.grey[200]
}
