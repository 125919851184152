const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY

const firebaseConfig = {
  apiKey,
  authDomain: `${projectId}.firebaseapp.com`,
  projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: '890732698956',
  appId: '1:890732698956:web:b82995db9c997bca13c3e9',
  measurementId: 'G-XBQ68GYJF9'
}

export default firebaseConfig
