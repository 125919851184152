export default {
  popper: {
    marginLeft: '6px'
  },
  tooltip: {
    fontFamily: "'DM Sans', sans-serif",
    backgroundColor: '#363b3f',
    fontSize: '15px'
  }
}
