import { colors } from '@material-ui/core'

export default {
  contained: {
    // boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
    // backgroundColor: '#d5d5d5',
    // 'Secondary&:hover': {
    //   color: '#000000'
    // },
    '&:hover': {
      color: '#000000'
    },
    borderRadius: '8px'
  },
  root: {
    // color: '#363b3f',
    fontFamily: "'Fira Sans', sans-serif",
    fontWeight: 'bold'
  }
}
